<template>
  <div class="page-footer" :style="{ color: color }">
    <div>
      <span>高质量发展研究院技术支持</span>
    </div>
    <div>
      <span>{{ time }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "#fff"
    }
  },
  data() {
    return {
      time: process.env.updateTime
    };
  }
};
</script>

<style lang="less" scoped>
.page-footer {
  text-align: center;
  line-height: 1.8em;
  font-size: 12px;
  transform: scale(0.8);
}
.version:hover {
  text-decoration: underline;
}
</style>