<template>
  <a-dropdown>
    <div class="my-hover">
        <a-icon style="font-size: 16px" type="download" />
        <span style="padding-left: 4px">下载客户端</span>
    </div>
    <div class="download" slot="overlay">
        <div class="item">
            <div class="title">移动端</div>
            <div class="subtitle">扫码下载手机 APP</div>
            <div class="qrcode">
                <img src="http://app.njszy.com/4c423ba48f2146d10e3e.png" alt="">
            </div>
        </div>
        <div class="item">
            <div class="title">桌面端</div>
            <div class="subtitle">适合桌面操作系统设备</div>
            <div class="extra">
      <img src="https://s.upapi.cn/2022/07/22/4960881eb2fd34e5271eea1ce853e061/company-logo.png" alt="">
            <a-button type='primary' @click="download">立即下载</a-button>
            </div>
      
        </div>
    </div>
 
 
  </a-dropdown>
</template>

<script>
export default {
  methods: {
    download() {
        window.open('https://portal.njszy.com/desktop/%E7%93%B4%E4%BA%91%E6%95%B0%E5%AD%97%E5%8C%96%E4%BA%91%E5%B9%B3%E5%8F%B0%20Setup%201.0.1.exe', '_blank')
    }
   
  },
};
</script>

<style lang="less" scoped>
.download {
    display: flex;
    gap: 24px;
    background-color: #fff;
    box-shadow: 0 0 16px rgba(0, 0, 0, .3);
    padding: 24px;
    border-radius: 8px;

    .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        border-right: 1px solid #e0e0e0;
        padding-right: 24px;
        .title {
            font-weight: bold;
            margin-bottom: 4px;
        }
        .subtitle {
            color: #999;
            margin-bottom: 12px;
            
        }
        img {
            height: 82px;
            width: 82px;
        }
    }
    .item:last-child {
        border-right-width: 0;
        padding-right: 0;
    }

    .qrcode {
        border: 1px solid #e0e0e0;
        border-radius: 8px;
        height: 130px;
        width: 130px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            height: 95%;
            width: 95%;
        }
    }

    .extra {
        height: 130px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }


}

</style>