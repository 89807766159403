<template>
  <div class="my-hover" @click="route">
    <a-badge :count="notReadTotal">
      <a-icon type="bell" class="icon" />
    </a-badge>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  computed: {
    ...mapState("socket", ["flag"]),

    ...mapState("message", ["notReadList", "notReadTotal"]),
    isMessageCenter() {
      return this.$route.path === "/message-center";
    },
  },

  watch: {
    flag(newValue) {
      if (typeof newValue === "string" && newValue.includes("task_review")) {
        console.log("通知组件收到了更新的动作");
        this.getList();
      }
    },
  },

  mounted() {
    if (this.isMessageCenter) {
      return;
    }
    this.getList();
  },

  methods: {
    ...mapActions("message", ["getMessageList"]),

    getList() {
      this.getMessageList({
        pageNum: 1,
        pageSize: 100,
        isRead: 0,
      }).then(() => {
        if (this.notReadList.length > 0) {
          this.getNotificationPermission().then(() => {
            this.notificate(this.notReadList[0]);
          });
        }
      });
    },

    route() {
      if (this.isMessageCenter) {
        return;
      }
      this.$router.push("/message-center");
    },

    getNotificationPermission() {
      return new Promise((resolve, reject) => {
        if (!window.Notification) {
          reject("您的浏览器不支持此功能，请更换浏览器！");
        } else {
          if (window.Notification.permission === "granted") {
            resolve();
          } else if (window.Notification.permission === "default") {
            window.Notification.requestPermission().then((permission) => {
              if (permission === "granted") {
                resolve();
              } else if (permission === "default") {
                reject("请点击允许按钮！");
              } else {
                reject(
                  "您已拒绝授权，如需此功能，请到浏览器设置页面进行设置！"
                );
              }
            });
          } else {
            reject("您已拒绝授权，如需此功能，请到浏览器设置页面进行设置！");
          }
        }
      });
    },

    notificate(info) {
      const title = info.title;
      const options = {
        body: info.content,
        icon: "https://portal.njszy.com/img/small-logo.0a905d4f.png",
      };
      const notification = new Notification(title, options);

      const that = this;
      notification.onclick = function () {
        // 后期根据类型去修改
        if (info.extra) {
          that.$router.push(info.extra);
        } else {
          that.$router.push("/message-center");
        }
        notification.close();
      };
    },
  },
};
</script>

<style lang="less" scoped>
.icon {
  font-size: 15px;
}
</style>