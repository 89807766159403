<template>
  <a-input-search
    placeholder="项目快速搜索"
    @search="onSearch"
    style="width: 80%; margin-left: 10%"
  />
</template>

<script>
export default {
  methods: {
    onSearch(value) {
      let keyWord = value ?? "";
      const path = "/produce/overview";
      if (this.$route.path === path) {
        const { keyWord: param } = this.$route.query;
        if (keyWord !== param) {
          this.$router.replace({
            query: {
              keyWord,
            },
          });
        }
      } else {
        this.$router.push(path + "?keyWord=" + keyWord);
      }
    },
  },
};
</script>