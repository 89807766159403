

<script>
import { mapGetters, mapState } from "vuex";
export default {
  props: {
    collapsed: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      openKeys: [],
      selectedKeys: [],
    };
  },

  mounted() {
    this.setMenu(this.$route.path);
  },
  watch: {
    $route: function (newRoute) {
      // 如果不是显示在菜单中的路由，那就不做任何处理。

      const routeObj = this.getCurrentRoute(newRoute.path);

      if (routeObj && routeObj.type !== "button") {
        this.setMenu(newRoute.path);
      }
    },

    collapsed(newValue) {
      if (newValue) {
        // 如果用户主动折叠起来，那 openKeys 就清空，这样就不会主动弹起
        this.openKeys = [];
      }
    },
  },
  computed: {
    ...mapState("auth", ["menu"]),
    ...mapGetters("auth", ["getCurrentRoute"]),
  },
  methods: {
    renderMenu(list) {
      return list.map((item) => {
        if (item.children && item.children.length > 0) {
          return (
            <a-sub-menu key={item.path}>
              <span slot="title">
                {item.icon && <a-icon type={item.icon} />}
                <span>{item.name}</span>
              </span>
              {this.renderMenu(item.children)}
            </a-sub-menu>
          );
        } else {
          return (
            <a-menu-item key={item.path}>
              {item.path.indexOf("http") === 0 ? (
                <a class="test-a" target="_blank" href={item.path}>
                  {item.name}
                </a>
              ) : item.path.indexOf(".html") > -1 ? (
                <a
                  class="test-a"
                  target="_blank"
                  href={window.location.origin + item.path}
                >
                  {item.name}
                </a>
              ) : (
                <router-link to={item.path}>
                  {item.icon && <a-icon type={item.icon} />}
                  <span>{item.name}</span>
                </router-link>
              )}
            </a-menu-item>
          );
        }
      });
    },

    setMenu(path) {
      const pathList = path.split("/");
      pathList.pop();

      // 如果是折叠状态，那就不需要设置 openKeys 了，也就意味着不会弹出子菜单了
      if (!this.collapsed) {
        // 需要把每一层的 submenu 都渲染出来
        if (path.length > 2) {
          let arr = [];
          while (pathList.length > 1) {
            arr.push(pathList.join("/"));
            pathList.pop();
          }
          this.openKeys = arr;
        } else {
          this.openKeys = [pathList.join("/")];
        }
      }

      this.selectedKeys = [path];
    },

    // SubMenu 展开/关闭的回调
    openChange(keys) {
      console.log("keys", keys);
      this.openKeys = keys;
    },
  },

  render() {
    return (
      <a-menu
        theme="dark"
        mode="inline"
        openKeys={this.openKeys}
        selectedKeys={this.selectedKeys}
        onOpenChange={this.openChange}
      >
        {this.renderMenu(this.menu)}
      </a-menu>
    );
  },
};
</script>