<template>
  <a-layout class="layout">
    <a-layout-sider v-model="collapsed" :trigger="null" collapsible width="200" class="sider">
      <img v-if="collapsed" alt="logo" class="small-logo" src="@/assets/small-logo2.png" />
      <img v-else alt="logo" class="logo" src="@/assets/logo.png" />
      <div class="menu-wrapper">
        <Menu :collapsed="collapsed" />
      </div>
      <Search v-if="$getPermission('/produce/overview')" />
      <div class="footer" v-if="!collapsed">
        <PageFooter />
      </div>
    </a-layout-sider>
    <a-layout>
      <a-layout-header class="header" :style="{ left: collapsed ? '80px' : '200px' }">
        <a-icon
          class="trigger"
          :type="collapsed ? 'menu-unfold' : 'menu-fold'"
          @click="() => (collapsed = !collapsed)"
        />
        <div class="right" style="margin-left: auto">
          <Time />
          <Notice />
          <full-screen />
          <DownloadApp />

          <Avatar />
        </div>
      </a-layout-header>
      <a-layout-content
        class="page-content"
        :style="{ 'margin-left': collapsed ? '80px' : '200px' }"
        v-if="!loading"
      >
        <Tabs :collapsed="collapsed" v-show="$route.path !== '/workplace'" />
        <div class="page-body">
          <keep-alive :include="keepAliveList">
            <router-view></router-view>
          </keep-alive>

          <first-login />
        </div>
      </a-layout-content>
    </a-layout>

    <!-- <div class="float-button">
      <a-tooltip title="AI搜索">
        <a-button type="primary" shape="circle" icon="search" @click="aiSearch" />
      </a-tooltip>
    </div>-->
  </a-layout>
</template>
<script>
import Menu from "./menu";
import DownloadApp from "./components/download-app.vue";
import Search from "./components/search.vue";
import Time from "./time.vue";
import Avatar from "./avatar";
import Notice from "./components/notice.vue";
import Tabs from "./tabs";
import PageFooter from "@/components/page-footer";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import FullScreen from "./components/full-screen.vue";
import FirstLogin from "./components/first-login.vue";

import { fetchUserPermissionList } from "@/api/setting/permission";
import { fetchDetail } from "@/api/setting/user";
export default {
  components: {
    Menu,
    Search,
    DownloadApp,
    Time,
    Notice,
    Avatar,
    Tabs,
    PageFooter,

    FullScreen,
    FirstLogin
  },
  data() {
    return {
      collapsed: false,
      loading: true
    };
  },

  computed: {
    ...mapGetters("tabs", ["keepAliveList"]),
    ...mapState("auth", ["user"])
  },

  mounted() {
    // 登录完之后要把系统需要用到的数据字典保留下来，或者刷新页面数据字典也更新

    fetchDetail({
      uuid: this.user.uuid,
    }).then((v) => {
      console.log(".............. ", v);

      fetchUserPermissionList({
        uuid: this.user.uuid
      }).then(res => {
      
        if (res && Array.isArray(res.role)) {
          this.setUser({
            ...this.user,
            roles: res.role,
            employeeVo: v.employeeVo
          });
        }

        if (res && Array.isArray(res.permission)) {
          Promise.all([this.getMenu(res.permission), this.getDataDict()]).then(
            () => {
              this.loading = false;
            }
          );
        }
      });
    });
  },

  methods: {
    ...mapActions("auth", ["getMenu"]),
    ...mapMutations("auth", ["setUser"]),
    ...mapActions("setting", ["getDataDict"]),

    aiSearch() {
      if (this.$route.path !== "/lab/ai-search") {
        this.$router.push("/lab/ai-search");
      }
    }
  }
};
</script>
<style lang="less" scoped>
.layout {
  height: 100vh;
}

.sider {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100vh;
  overflow: auto;

  .logo {
    margin: 14px auto 8px;
    height: 30px;
    display: flex;
  }
  .small-logo {
    margin: 2px auto 0;
    height: 50px;
    display: flex;
  }
  .menu-wrapper {
    margin-bottom: 12px;
  }
  .footer {
    margin-top: 16px;
    margin-bottom: 12px;
    // position: fixed;
    // bottom: 8px;
    // left: 8px;
  }
}

.header {
  background-color: #001529;
  color: #fff;
  padding: 0;
  display: flex;
  align-items: center;
  line-height: 56px;
  height: 56px;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 99;
  transition: all 0.2s;
}

.page-content {
  margin-top: 56px;
  transition: all 0.2s;
  .page-body {
    margin-top: 56px;
  }
}

.float-button {
  position: fixed;
  bottom: 16px;
  right: 16px;
  z-index: 99;
}
</style>