<template>
  <div class="my-hover" @click="setScreen">
    <a-tooltip placement="bottom" :title="isFullScreen ? '退出全屏' : '全屏'">
      <a-icon type="fullscreen-exit" class="icon" v-if="isFullScreen" />
      <a-icon type="fullscreen" class="icon" v-else />
    </a-tooltip>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isFullScreen: false,
    };
  },
  methods: {
    setScreen() {
      console.log(document.fullscreenElement);
      // 点击切换全屏模式
      if (document.fullscreenElement) {
        document.exitFullscreen();
        this.isFullScreen = false;
      } else {
        document.documentElement.requestFullscreen();
        this.isFullScreen = true;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.icon {
  font-size: 15px;
}
</style>