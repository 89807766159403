<template>
  <div style="margin-right: 8px">{{ time }}</div>
</template>

<script>
import { getNow } from "@/utils/time";
export default {
  data() {
    return {
      time: "",
    };
  },

  mounted() {
    const timer = setInterval(() => {
      this.getTime();
    }, 1000);
    this.$once("hook:beforeDestroy", () => {
      clearInterval(timer);
    });
  },

  methods: {
    getTime() {
      this.time = getNow();
    },
  },
};
</script>