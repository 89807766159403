<template>
  <a-dropdown>
    <div class="header-avatar my-hover">
      <a-avatar
        v-if="user.header"
        size="small"
        shape="circle"
        :src="user.header"
      />
      <default-avatar
        :name="user.userName"
        :fromHead="user.name === 'admin'"
        v-else
      />
      <span class="name">{{ user.userName }}</span>
    </div>
    <a-menu :class="['avatar-menu']" slot="overlay">
      <a-menu-item
        @click="
          goPage(
            user.employeeVo
              ? '/human-resources/map/employee/detail?id=' + user.employeeVo.id
              : ''
          )
        "
      >
        <a-icon type="user" />
        <span>个人信息</span>
      </a-menu-item>
      <a-menu-divider />
      <a-menu-item @click="goPage('/personal-setting')">
        <a-icon type="setting" />
        <span>账号设置</span>
      </a-menu-item>
      <a-menu-divider />
      <a-menu-item @click="logout">
        <a-icon style="margin-right: 8px" type="poweroff" />
        <span>退出登录</span>
      </a-menu-item>
    </a-menu>
  </a-dropdown>
</template>

<script>
import DefaultAvatar from "@/components/default-avatar";
import { mapState } from "vuex";
export default {
  components: {
    DefaultAvatar,
  },

  data() {
    return {};
  },
  computed: {
    ...mapState("auth", ["user"]),
  },
  methods: {
    goPage(path) {
      this.$router.push(path);
    },

    logout() {
      // 退出登录，清空数据缓存
      window.localStorage.clear();
      this.$router.push("/login");
    },
  },
};
</script>

<style lang="less" scoped>
.header-avatar {
  margin-right: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  .name {
    margin-left: 8px;
    font-weight: 500;
  }
}
.avatar-menu {
  width: 150px;
}
</style>