<template>
  <div>
    <div v-if="tabList.length"
         class="tabs"
         :style="{ left: collapsed ? '80px' : '200px' }">
      <a-tabs v-model="activeKey"
              :tabBarStyle="{
          margin: '16px 16px 0 16px',
        }"
              @change="onTabChange"
              @edit="onTabEdit"
              type="editable-card"
              :hideAdd="true">
        <div slot="tabBarExtraContent">
          <a-tooltip placement="leftBottom">
            <template slot="title"> 删除所有标签页 </template>
            <a-icon type="close-circle"
                    @click="closeAll"
                    style="font-size: 18px" />
          </a-tooltip>
        </div>
        <a-tab-pane v-for="item in tabList"
                    :key="item.path"
                    :tab="item.name">
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
export default {
  props: {
    collapsed: Boolean,
  },

  data() {
    return {
      activeKey: null,
    };
  },

  computed: {
    ...mapGetters("auth", ["getCurrentRoute"]),
    ...mapState("tabs", ["tabList"]),
  },

  mounted() {
    // 组件第一次渲染，把工作台默认添加进去
    const workplacePath = "/workplace";
    const workplace = this.getCurrentRoute(workplacePath);
    if (workplace) {
      if (
        this.tabList.findIndex((item) => item.path === workplacePath) === -1
      ) {
        this.setTabList([
          {
            name: workplace.name,
            path: workplacePath,
            fullPath: workplacePath,
          },
          ...this.tabList,
        ]);
      }
    }

    // 组件第一次渲染，根据路由信息，渲染 tab
    const { path, fullPath, name } = this.$route;
    const obj = this.getCurrentRoute(path);
    if (obj) {
      if (this.tabList.findIndex((item) => item.path === path) === -1) {
        this.setTabList([
          ...this.tabList,
          {
            name: obj.name,
            path,
            fullPath,
            componentName: name,
          },
        ]);
      }

      this.activeKey = path;
    }
  },

  methods: {
    ...mapMutations("tabs", ["setTabList"]),

    onTabChange(key) {
      const obj = this.tabList.find((item) => item.path === key);
      this.$router.push(obj.fullPath);
    },

    onTabEdit(targetKey, action) {
      if (action === "remove") {
        if (targetKey === "/workplace") {
          this.$message.error("请勿删除工作台");
          return;
        }

        if (this.tabList.length === 1) {
          this.$message.error("无法再删除");
          return;
        }

        const routes = this.$router.getRoutes(); // 获取所有的路由记录

        let flag = false;

        const obj = routes.find((item) => item.path === targetKey);
        if (obj && obj.meta && obj.meta.alertWhenLeaving) {
          flag = true;
        }

        console.log("flag", flag);

        if (flag) {
          const that = this;
          this.$confirm({
            title: "关闭后系统自动清除数据，是否继续？",
            okText: "是",
            okType: "danger",
            cancelText: "否",
            onOk() {
              that.deleteTab(targetKey);
            },
          });
        } else {
          this.deleteTab(targetKey);
        }
      }
    },

    deleteTab(targetKey) {
      const index = this.tabList.findIndex((item) => item.path === targetKey);

      if (index > -1) {
        this.tabList.splice(index, 1);
        this.setTabList([...this.tabList]);

        if (this.$route.path === targetKey) {
          // 当要删除的 tab 正在显示时，才替换路由到 tabList 最新的一条
          this.$router.replace(this.tabList[this.tabList.length - 1].fullPath);
        }
      }
    },

    closeAll() {
      const that = this;
      this.$confirm({
        title: "确认要删除所有的标签吗？",
        onOk() {
          const workplacePath = "/workplace";
          const workplace = that.getCurrentRoute(workplacePath);
          if (workplace) {
            that.setTabList([
              {
                name: workplace.name,
                path: workplacePath,
                fullPath: workplacePath,
              },
            ]);
            that.$router.push(workplacePath);
          }
        },
      });
    },
  },

  watch: {
    $route: function (newRoute) {
      const routeObj = this.getCurrentRoute(newRoute.path);

      if (routeObj) {
        const alreadyIndex = this.tabList.findIndex(
          (item) => item.path === newRoute.path
        );

        if (alreadyIndex > -1) {
          this.tabList.splice(alreadyIndex, 1, {
            name: routeObj.name,
            path: newRoute.path,
            fullPath: newRoute.fullPath,
            componentName: newRoute.name, // 路由组件的 name，用来缓存
          });
          this.setTabList([...this.tabList]);
        } else {
          this.setTabList([
            ...this.tabList,
            {
              name: routeObj.name, // 中文名
              path: newRoute.path, // 路径
              fullPath: newRoute.fullPath, // 完整路径，可能包括查询参数
              componentName: newRoute.name, // 路由组件的 name，用来缓存
            },
          ]);
        }

        this.activeKey = newRoute.path;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.tabs {
  position: fixed;
  top: 56px;
  right: 0;
  z-index: 99;
  transition: all 0.2s;
  background-color: #f0f2f5;
}
</style>