var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-form',{attrs:{"form":_vm.form,"colon":false},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"新密码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'password',
          { rules: [{ required: true, validator: _vm.validator1 }] },
        ]),expression:"[\n          'password',\n          { rules: [{ required: true, validator: validator1 }] },\n        ]"}],attrs:{"type":"password"}})],1),_c('a-form-item',{attrs:{"label":"确认新密码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'password2',
          {
            rules: [{ required: true, validator: _vm.validator2 }],
          },
        ]),expression:"[\n          'password2',\n          {\n            rules: [{ required: true, validator: validator2 }],\n          },\n        ]"}],attrs:{"type":"password"}})],1),_c('div',{class:_vm.buttonPosition},[_c('a-button',{attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" 确认修改 ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }