<template>
  <a-modal
    title="首次登录必须修改密码"
    :closable="false"
    :footer="null"
    width="400px"
    :visible="user.firstLogin === 0"
  >
    <PasswordEditor :next="next" buttonPosition="right" />
  </a-modal>
</template>

<script>
import PasswordEditor from "@/components/password-editor";
import { mapMutations, mapState } from "vuex";

export default {
  components: {
    PasswordEditor,
  },

  computed: {
    ...mapState("auth", ["user"]),
  },

  methods: {
    ...mapMutations("auth", ["setUser"]),
    next() {
      this.setUser({
        ...this.user,
        firstLogin: 1,
      });
    },
  },
};
</script>