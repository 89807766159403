<template>
  <div>
    <a-form :form="form" :colon="false" @submit="handleSubmit">
      <a-form-item label="新密码">
        <a-input
          type="password"
          v-decorator="[
            'password',
            { rules: [{ required: true, validator: validator1 }] },
          ]"
        />
      </a-form-item>
      <a-form-item label="确认新密码">
        <a-input
          type="password"
          v-decorator="[
            'password2',
            {
              rules: [{ required: true, validator: validator2 }],
            },
          ]"
        />
      </a-form-item>
      <div :class="buttonPosition">
        <a-button type="primary" html-type="submit"> 确认修改 </a-button>
      </div>
    </a-form>
  </div>
</template>



<script>
import { editBaseInfo } from "@/api/setting/user";
import { mapState } from "vuex";
export default {
  props: {
    next: {
      type: Function,
      default: () => {},
    },
    buttonPosition: {
      type: String,
      default: "left",
    },
  },

  data() {
    return {
      form: this.$form.createForm(this),
    };
  },

  computed: {
    ...mapState("auth", ["user"]),
  },

  methods: {
    // 自定义校验规则
    validator1(rule, value) {
      let testResult = /[0-9]+/.test(value) && /[a-zA-Z]+/.test(value);

      return new Promise((resolve, reject) => {
        if (!value) {
          reject("请输入新密码！");
        } else if (value.length < 8) {
          reject("密码不小于8位！");
        } else if (!testResult) {
          reject("密码需包含字母和数字！");
        } else {
          resolve();
        }
      });
    },

    validator2(rule, value) {
      console.log("value ", value);
      const password = this.form.getFieldValue("password");

      return new Promise((resolve, reject) => {
        if (!value) {
          reject("请输入新密码！");
        } else if (value !== password) {
          reject("密码不一致！");
        } else {
          resolve();
        }
      });
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          editBaseInfo({
            ...this.user,
            password: this.$encrypt.encrypt(values.password),
          }).then(() => {
            this.next();
          });
        }
      });
    },
  },
};
</script>